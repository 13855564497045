@keyframes animateBottomTop {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes animateBottomTop {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes animateBorder {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes animateBorder {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
