.BackButton {
  position: fixed;
  top: 50%;
  height: 235px;
  width: 235px;
  background: rgba(56, 56, 63, 0.5);
  color: var(--white);
  font-size: 20px;
  border: none;
}

.BackButtonRight {
  right: -165px;
  transform: translateY(-50%) rotateZ(90deg);
  -webkit-transform: translateY(-50%) rotateZ(90deg);
  -moz-transform: translateY(-50%) rotateZ(90deg);
  -ms-transform: translateY(-50%) rotateZ(90deg);
  -o-transform: translateY(-50%) rotateZ(90deg);
}

.BackButtonLeft {
  left: -165px;
  transform: translateY(-50%) rotateZ(-90deg);
  -webkit-transform: translateY(-50%) rotateZ(-90deg);
  -moz-transform: translateY(-50%) rotateZ(-90deg);
  -ms-transform: translateY(-50%) rotateZ(-90deg);
  -o-transform: translateY(-50%) rotateZ(-90deg);
}

/* .BackButton p {
  top: 82%;
} */

/* .BackButton:after {
  content: "";
  background: #e2b8b6;
  display: block;
  position: absolute;
  padding-top: 100%;
  padding-left: 150%;
  margin-left: -20px !important;
  margin-top: -75%;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.BackButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
} */
