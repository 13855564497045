:root {
  --white: #f1f1f1;
  --textFontSize: 1.5rem;
  --dark: #0a0909;
  --body-bg: #17181c;
  --primary: #198996;
  --primaryDark: #0f4248;
  --danger: #af1f23;
  --success: #007d59;
  --info: #21405c;
}

@font-face {
  font-family: "f1-bold";
  src: url(./assets/Font/f1-display-bold.ttf) format("truetype");
}

@font-face {
  font-family: "f1-regular";
  src: url(./assets/Font/f1-display-regular.ttf) format("truetype");
}

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  background: var(--body-bg);
  font-family: f1-regular;
}

p {
  margin: 0;
}

p,
span,
div {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:focus {
  outline: none;
}

button,
a {
  outline: none;
  outline: 0;
}

button:focus,
a:focus {
  outline: none;
  outline: 0;
}

#mainBox {
  height: calc(100vh - 85px);
}

.title {
  width: max-content;
  font-family: "f1-bold";
}

.padding {
  padding: 0 40px;
}
/* login page */

.text-primary {
  color: var(--primary) !important;
}

.border-primary {
  border-color: var(--primary);
}

.danger {
  color: var(--danger) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-danger {
  background-color: var(--danger) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

.bg-info {
  background-color: var(--info) !important;
}
.text-info {
  color: var(--info) !important;
}

.spinner-border {
  border-width: 0.22rem;
  vertical-align: middle;
}

.scan-with-qrcode-or-rfid {
  cursor: pointer;
  color: var(--primary);
  font-weight: bold;
  border-bottom: 2px solid var(--primary);
}

.qr-code_class {
  border: 2px dashed var(--primaryDark);
}

.switchCamera {
  right: 30px;
  bottom: 30px;
  width: 50px;
  z-index: 10;
}

section {
  position: relative;
  height: 100%;
}

.mainButton {
  background: var(--primaryDark);
  color: var(--white);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  overflow: hidden;
  border-style: none !important;
}

.mainButton:hover,
.btn:hover,
.btn:disabled {
  background: transparent !important;
  color: var(--primary) !important;
  border-color: var(--primaryDark) !important;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  overflow: hidden;
}
.btn:disabled {
  background-color: #202319;
}

.border-title-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: #bebebe;
  animation: animateBorder 0.7s ease 0s forwards;
  -webkit-animation: animateBorder 0.7s ease 0s forwards;
}

.modal-content {
  background-color: var(--dark);
}

.input_field {
  background: #0b0d0f;
  color: #a1a1a1;
  border-radius: 3px;
  border: none;
  letter-spacing: 10px;
  outline: none;
}

.boxWithArrow {
  border: 2px solid var(--primaryDark);
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  outline: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  animation: animateBottomTop 0.5s ease 0s forwards;
  -webkit-animation: animateBottomTop 0.5s ease 0s forwards;
}

.locationTitle,
.points {
  font-family: "f1-bold";
}

.tinyText {
  font-size: 13px;
}

.toast {
  width: auto !important;
}

/* remove play button */

*::-webkit-media-controls {
  display: none !important;
  appearance: none;
  -webkit-appearance: none;
}

*::-webkit-media-controls-panel {
  display: none !important;
  appearance: none;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  appearance: none;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  appearance: none;
  -webkit-appearance: none;
}
