.header {
  background: #000000;
  width: 100%;
  height: 100px;
}

.buildVersion {
  color: #7d7c7c;
  font-size: 12px;
}
