.daysCheckbox {
  justify-content: space-between;
  margin-right: 20px;
  color: white;
  position: relative;
}

.daysCheckbox input {
  margin-right: 10px;
}

.daysCheckbox input[type="checkbox"] + label::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 20px;
  width: 20px;
  left: 0;
  top: 0px;
  background-color: white;
  font-family: "f1-regular";
  border-radius: 3px;
}

.daysCheckbox input[type="checkbox"]:checked + label::before {
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 20px;
  width: 20px;
  left: 0;
  top: 0px;
  background-color: #007d59;
  font-family: "f1-regular";
  border-radius: 3px;
  border: 1px solid #03996e;
}

.daysCheckbox input[type="checkbox"]:checked + label::after {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
  display: block;
  position: absolute;
  left: 3px;
  top: 0px;
}
